import React from "react";

function Main() {
    return (
        <div>
            <h1>Main</h1>
        </div>
    );
    }

export default Main;